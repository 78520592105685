.service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3A486D;
}

.service-header {
    color: white;
    font-size: 2.2em;
    padding-left: 20px;
}

.maintenance {
    padding-left: 40px;
    color: white;
}

.maintenance-info, .swim-info {
    color: white;
    width: 90%;
    padding-left: 40px;
    font-size: .94em;
}

.top-swim-title {
    padding-top: 60px;
}

.swim-title {
    padding-left: 40px;
    color: white;
}

.service-pic, .lesson-pic {
    display: block;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .service-header {
        font-size: 1.5em;
    }

    .maintenance, .swim-title {
        font-size: 1em;
    }

    .maintenance-info, .swim-info {
        font-size: .75em;
    }
}

@media screen and (max-width: 830px) {
    .service {
        grid-template-columns: 1fr;
    }

    .maintenance-info, .swim-info {
        padding-right: 20px;
        width: 80%;
    }
}