.apply {
    width: 100%;
    background-color: #3A496C;
}

.apply-top {
    display: grid;
    grid-template-columns: 2fr 1.25fr;
}

.apply-head {
    position: relative;
    display: inline-block;
    width: 100%;
}

.header-holder {
    color: white;
    font-size: 1.5em;
    position: absolute;  
    height: 100%;
    text-align: left;    
    width: 100%;
    padding-left: 40px;
    padding-top: 40px;
    font-weight: 700;
}

.requirements {
    width: 100%;
    background-color: #3A496C;
    height: 314px;
    color: white;
    overflow: hidden;
}

.requirements-header {
    padding-left: 10px;
    margin: 0;
}

.req {
    padding-left: 20px;
    font-size: .85em;
}

.req-bullet {
    padding-left: 30px;
    font-size: .75em
}

a {
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

a:hover {
  color: tomato;
}

#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 400px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; 
  z-index: 2; 
  
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -300px;
  padding: 50px;
  padding-top: 100px;
  height: 164px;
  margin-top: -77px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  transform: none;
}

.req-lower {
    display: grid;
    grid-template-columns: 2fr 1.25fr;
}

.apply-head-lower {
    display: flex;
    justify-content: center;
    align-items: center;
}

.apply-online-btn {
    margin-left: 15px;
    cursor: pointer;
}

.w4-btn {
    margin-right: 15px;
}

.apply-online-btn, .w4-btn {
    border-top: 1px solid #96d1f8;
    background: #65a9d7;
    background: -webkit-gradient(linear, left top, left bottom, from(#3e779d), to(#65a9d7));
    background: -webkit-linear-gradient(top, #3e779d, #65a9d7);
    background: -moz-linear-gradient(top, #3e779d, #65a9d7);
    background: -ms-linear-gradient(top, #3e779d, #65a9d7);
    background: -o-linear-gradient(top, #3e779d, #65a9d7);
    padding: 5px 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
    -moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
    box-shadow: rgba(0,0,0,1) 0 1px 0;
    text-shadow: rgba(0,0,0,.4) 0 1px 0;
    color: white;
    font-size: 35px;
    font-family: Georgia, serif;
    text-decoration: none;
    vertical-align: middle;
}

.apply-online-btn:hover, .w4-btn:hover {
    border-top-color: #28597a;
    background: #28597a;
    color: #ccc;
}

.apply-online-btn:active, .w4-btn:active {
    border-top-color: #1b435e;
    background: #1b435e;
}

.req-header2 {
    padding-left: 25px;
    opacity: .9;
}

.req2 {
    padding-left: 35px;
    opacity: .9;
}

@media screen and (max-width: 1100px) {
    .stock-life {
      max-width: 100%;
      height: auto;
      width: auto\9; /* ie8 */
    }

    #menuToggle {
      display: block;
      position: relative;
      top: 60px;
      left: 350px;
      
      z-index: 1;
      
      -webkit-user-select: none;
      user-select: none;
    }
}

@media screen and (max-width: 1000px) {
    .requirements-header {
      font-size: .75em;
    }

    .req {
      font-size: .65em;
    }

    .req-bullet {
      font-size: .5em;
    }

    #menuToggle {
      left: 300px;
    }
}

@media screen and (max-width: 875px) {
  .apply-top {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  #menuToggle {
    left: 780px;
  }

  .requirements-header {
    font-size: 1.5em;
  }

  .req {
    font-size: .75em;
  }

  .req-bullet {
    font-size: .65em;
  }
}

@media screen and (max-width: 780px) {
  #menuToggle {
    left: 680px;
  }
}

@media screen and (max-width: 700px) {
  #menuToggle {
    left: 600px;
  }
}

@media screen and (max-width: 620px) {
  #menuToggle {
    left: 530px;
  }
}

@media screen and (max-width: 550px) {
  #menuToggle {
    left: 450px;
  }

  .apply-online-btn, .w4-btn {
    font-size: .75em;
  }
}

@media screen and (max-width: 480px) {
  #menuToggle {
    top: 80px;
    left: 380px;
  }
}

@media screen and (max-width: 480px) {
  #menuToggle {
    left: 320px;
  }
}

@media screen and (max-width: 375px) {
  .header-holder {
    padding: 5px;
  }

  #menuToggle {
    left: 275px;
  }
}