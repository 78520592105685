.header-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.img-holder {
    display: flex;
    justify-content: center;
}

.right-btns, .left-btns {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header-btn {
    border: none;
    font-size: 1em;
    font-weight: 700;
    color: #545759;
    cursor: pointer;
    text-decoration: none;
}

.header-btn:hover {
    color: #7492BB;
}

.logo {
    height: 120px;
    width: 150px;
}

@media screen and (max-width: 1100px) {
    .header-bar {
        display: grid;
        grid-template-columns: 1fr .75fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    .right-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        text-align: center;
    }
    .left-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .logo {
        height: 75px;
        width: 100px;
    }

    .header-btn {
        font-size: .75em;
    }
}