.testimonial {
    padding-bottom: 25px;
    padding-top: 25px;
}

.test-odd, .testimonials-header {
    background-color: #3A486D;
}

.test-even {
    background-color: #F6F6F6;
}

.test-info-even {
    color: #3A486D;
}

.testimonials-header {
    margin: 0;
    color: white;
    padding-bottom: 25px;
    padding-left: 25px;
}

.test-info {
    color: white;
}

.test-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.testimonial-name {
    text-align: right;
    padding-right: 75px;
}

.test-img-holder {
    display: flex;
    justify-content: center;
}

.kelly-img {
    height: 500px;
    width: 350px;
}

.speech-bubble-ds {
    background: #efefef;
    border: 1px solid #a7a7a7;
    -webkit-border-radius: 4px;
            border-radius: 4px;
    -webkit-box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 auto 40px;
    max-width: 400px;
    padding: 15px;
    position: relative;
}

.speech-bubble-ds p {
    margin-bottom: 10px;
}
.speech-bubble-ds p:last-of-type {
    margin-bottom: 0;
}

.speech-bubble-ds-arrow {
    border-left: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.2);
    bottom: -25px;
    position: absolute;
    right: 15px;
}
.speech-bubble-ds-arrow::before {
    border-left: 23px solid transparent;
    border-top: 23px solid #a7a7a7;
    bottom: 2px;
    content: "";
    position: absolute;
    right: 5px;
}
.speech-bubble-ds-arrow::after {
    border-left: 21px solid transparent;
    border-top: 21px solid #efefef;
    bottom: 4px;
    content: "";
    position: absolute;
    right: 6px;
}

.pro-img {
    width: 400px;
    height: 300px;
}

.pro-img2 {
    height: 500px;
    widows: 400px;
}

.pro-img3 {
    height: 400px;
    width: 400px;
}

@media screen and (max-width: 1000px) {
    .testimonial-name {
        text-align: right;
        padding-right: 20px;
    }
}

@media screen and (max-width: 750px) {
    .test-left {
        display: grid;
        grid-template-columns: 1fr;
    }

    .pro-img2, .pro-img3 {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .pro-img3 {
        height: 300px;
        width: 300px;
    }

    .pro-img {
        width: 300px;
    }
}