.paypal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #39486D;
    height: 83vh;
}

.payment-header {
    color: white;
    font-size: 3em;
    margin-top: 0px;
}

.payment-instructions {
    color: white;
}

.paypal-info {
    position: relative;
    background-color: rgba(0, 0, 0, .65);
    height: 400px;
    width: 325px;
    border-radius: 10px;
    padding: 35px;
    padding-top: 50px;
    color: white;
}

.total-payment-input {
    margin-bottom: 25px;
}

.payment-option {
    color: red;
}