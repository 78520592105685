.contact {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 83vh;
}

.upper-contact {
    background-color: #F6F6F6;
    height: 100%;
}

.lower-contact {
    background-color: #3A486D;
}

.contact-header {
    padding-left: 30px;
    color: #3A486D;
}

.contact-info {
    padding-left: 50px;
    color: #3A486D;  
}