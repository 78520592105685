.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3A486D;
}

.about-title {
    padding-left: 20px;
}

.about-holder{
    color: white;
    font-size: 1.5em;
    text-align: left;    
    width: 100%;
    padding-top: 24px;
    font-weight: 700;
    text-shadow: 1px 1px #606060;
}

.services-header {
    padding-left: 40px;
    font-size: 1em;
    text-shadow: 1px 1px #606060;
}

.services-bottom {
    padding-bottom: 50px;
}

.about-us, .services {
    padding-left: 40px;
    font-size: .65em;
    font-weight: 400;
    width: 70%;
}

.patio-img, .lifeguard {
    display: block;
    max-width: 100%;
    height: auto;
}

.lifeguard-header {
    padding-top: 30px;
    padding-left: 40px;
    color: white;
    font-size: 1.5em;
}

.lifeguard-points {
    color: white;
    text-shadow: 1px 1px #606060;
    font-size: 1em;
    padding-left: 60px;
    width: 70%;
}

@media screen and (max-width: 1100px) {
    .about-title {
        font-size: 1em;
    }

    .about-us, .services {
        font-size: .5em;
    }

    .lifeguard-points {
        font-size: .75em;
    }
}

@media screen and (max-width: 950px) {
    .about {
        grid-template-columns: 1fr;
    }
}