.homepage {
    position: relative;
    display: inline-block;
}

.hometext {
    color: white;
    font-size: 1.5em;
    position: absolute;  
    height: 100%;
    text-align: left;    
    width: 100%;
    padding-left: 40px;
    font-weight: 700;
}

.home-header {
    text-shadow: 2px 2px black;
    margin-bottom: 0px;
}

.about-home {
    width: 60%;
    font-size: .75em;
    padding-top: 0px;
    color: white;
    text-shadow: 2px 2px black;
    padding-left: 20px;
}

.teamwork {
    font-size: .75em;
    width: 60%;
    padding-left: 20px;
    text-shadow: 2px 2px black;
}

.home-img {
    display: block;
    background-size: contain;
    max-width: 100%;
    height: 100%;
}

@media screen and (max-width: 750px) {
    .home-img {
        width: 100%;
        height: 600px;
        background-size: cover;
    }
}

@media screen and (max-width: 620px) {
    .home-img {
        width: 100%;
        height: 600px;
        background-size: contain;
    }
}

@media screen and (max-width: 555px) {
    .home-img {
        width: 100%;
        height: 400px;
        background-size: cover;
    }

    .home {
        background-color: #3A496C;
        height: 100vh;
    }

    .hometext {
        color: white;
        font-size: 1em;
        position: absolute;  
        height: 100%;
        text-align: left;    
        width: 100%;
        padding-left: 100px;
        padding-top: 40px;
        font-weight: 700;
    }
}

@media screen and (max-width: 490px) {
    .company-name {
        font-size: 1em;
    }
    .hometext {
        color: white;
        font-size: 1em;
        position: absolute;  
        height: 100%;
        text-align: left;    
        width: 100%;
        padding-left: 20px;
    }
}