body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.homepage {
    position: relative;
    display: inline-block;
}

.hometext {
    color: white;
    font-size: 1.5em;
    position: absolute;  
    height: 100%;
    text-align: left;    
    width: 100%;
    padding-left: 40px;
    font-weight: 700;
}

.home-header {
    text-shadow: 2px 2px black;
    margin-bottom: 0px;
}

.about-home {
    width: 60%;
    font-size: .75em;
    padding-top: 0px;
    color: white;
    text-shadow: 2px 2px black;
    padding-left: 20px;
}

.teamwork {
    font-size: .75em;
    width: 60%;
    padding-left: 20px;
    text-shadow: 2px 2px black;
}

.home-img {
    display: block;
    background-size: contain;
    max-width: 100%;
    height: 100%;
}

@media screen and (max-width: 750px) {
    .home-img {
        width: 100%;
        height: 600px;
        background-size: cover;
    }
}

@media screen and (max-width: 620px) {
    .home-img {
        width: 100%;
        height: 600px;
        background-size: contain;
    }
}

@media screen and (max-width: 555px) {
    .home-img {
        width: 100%;
        height: 400px;
        background-size: cover;
    }

    .home {
        background-color: #3A496C;
        height: 100vh;
    }

    .hometext {
        color: white;
        font-size: 1em;
        position: absolute;  
        height: 100%;
        text-align: left;    
        width: 100%;
        padding-left: 100px;
        padding-top: 40px;
        font-weight: 700;
    }
}

@media screen and (max-width: 490px) {
    .company-name {
        font-size: 1em;
    }
    .hometext {
        color: white;
        font-size: 1em;
        position: absolute;  
        height: 100%;
        text-align: left;    
        width: 100%;
        padding-left: 20px;
    }
}
.about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3A486D;
}

.about-title {
    padding-left: 20px;
}

.about-holder{
    color: white;
    font-size: 1.5em;
    text-align: left;    
    width: 100%;
    padding-top: 24px;
    font-weight: 700;
    text-shadow: 1px 1px #606060;
}

.services-header {
    padding-left: 40px;
    font-size: 1em;
    text-shadow: 1px 1px #606060;
}

.services-bottom {
    padding-bottom: 50px;
}

.about-us, .services {
    padding-left: 40px;
    font-size: .65em;
    font-weight: 400;
    width: 70%;
}

.patio-img, .lifeguard {
    display: block;
    max-width: 100%;
    height: auto;
}

.lifeguard-header {
    padding-top: 30px;
    padding-left: 40px;
    color: white;
    font-size: 1.5em;
}

.lifeguard-points {
    color: white;
    text-shadow: 1px 1px #606060;
    font-size: 1em;
    padding-left: 60px;
    width: 70%;
}

@media screen and (max-width: 1100px) {
    .about-title {
        font-size: 1em;
    }

    .about-us, .services {
        font-size: .5em;
    }

    .lifeguard-points {
        font-size: .75em;
    }
}

@media screen and (max-width: 950px) {
    .about {
        grid-template-columns: 1fr;
    }
}
.header-bar {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.img-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.right-btns, .left-btns {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
}

.header-btn {
    border: none;
    font-size: 1em;
    font-weight: 700;
    color: #545759;
    cursor: pointer;
    text-decoration: none;
}

.header-btn:hover {
    color: #7492BB;
}

.logo {
    height: 120px;
    width: 150px;
}

@media screen and (max-width: 1100px) {
    .header-bar {
        display: grid;
        grid-template-columns: 1fr .75fr 1fr;
    }
}

@media screen and (max-width: 900px) {
    .right-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        text-align: center;
    }
    .left-btns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        text-align: center;
    }
}

@media screen and (max-width: 550px) {
    .logo {
        height: 75px;
        width: 100px;
    }

    .header-btn {
        font-size: .75em;
    }
}
.service {
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #3A486D;
}

.service-header {
    color: white;
    font-size: 2.2em;
    padding-left: 20px;
}

.maintenance {
    padding-left: 40px;
    color: white;
}

.maintenance-info, .swim-info {
    color: white;
    width: 90%;
    padding-left: 40px;
    font-size: .94em;
}

.top-swim-title {
    padding-top: 60px;
}

.swim-title {
    padding-left: 40px;
    color: white;
}

.service-pic, .lesson-pic {
    display: block;
    max-width: 100%;
    height: auto;
}

@media screen and (max-width: 1050px) {
    .service-header {
        font-size: 1.5em;
    }

    .maintenance, .swim-title {
        font-size: 1em;
    }

    .maintenance-info, .swim-info {
        font-size: .75em;
    }
}

@media screen and (max-width: 830px) {
    .service {
        grid-template-columns: 1fr;
    }

    .maintenance-info, .swim-info {
        padding-right: 20px;
        width: 80%;
    }
}
.testimonial {
    padding-bottom: 25px;
    padding-top: 25px;
}

.test-odd, .testimonials-header {
    background-color: #3A486D;
}

.test-even {
    background-color: #F6F6F6;
}

.test-info-even {
    color: #3A486D;
}

.testimonials-header {
    margin: 0;
    color: white;
    padding-bottom: 25px;
    padding-left: 25px;
}

.test-info {
    color: white;
}

.test-left {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.testimonial-name {
    text-align: right;
    padding-right: 75px;
}

.test-img-holder {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.kelly-img {
    height: 500px;
    width: 350px;
}

.speech-bubble-ds {
    background: #efefef;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0 auto 40px;
    max-width: 400px;
    padding: 15px;
    position: relative;
}

.speech-bubble-ds p {
    margin-bottom: 10px;
}
.speech-bubble-ds p:last-of-type {
    margin-bottom: 0;
}

.speech-bubble-ds-arrow {
    border-left: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.2);
    bottom: -25px;
    position: absolute;
    right: 15px;
}
.speech-bubble-ds-arrow::before {
    border-left: 23px solid transparent;
    border-top: 23px solid #a7a7a7;
    bottom: 2px;
    content: "";
    position: absolute;
    right: 5px;
}
.speech-bubble-ds-arrow::after {
    border-left: 21px solid transparent;
    border-top: 21px solid #efefef;
    bottom: 4px;
    content: "";
    position: absolute;
    right: 6px;
}

.pro-img {
    width: 400px;
    height: 300px;
}

.pro-img2 {
    height: 500px;
    widows: 400px;
}

.pro-img3 {
    height: 400px;
    width: 400px;
}

@media screen and (max-width: 1000px) {
    .testimonial-name {
        text-align: right;
        padding-right: 20px;
    }
}

@media screen and (max-width: 750px) {
    .test-left {
        display: grid;
        grid-template-columns: 1fr;
    }

    .pro-img2, .pro-img3 {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 400px) {
    .pro-img3 {
        height: 300px;
        width: 300px;
    }

    .pro-img {
        width: 300px;
    }
}
.apply {
    width: 100%;
    background-color: #3A496C;
}

.apply-top {
    display: grid;
    grid-template-columns: 2fr 1.25fr;
}

.apply-head {
    position: relative;
    display: inline-block;
    width: 100%;
}

.header-holder {
    color: white;
    font-size: 1.5em;
    position: absolute;  
    height: 100%;
    text-align: left;    
    width: 100%;
    padding-left: 40px;
    padding-top: 40px;
    font-weight: 700;
}

.requirements {
    width: 100%;
    background-color: #3A496C;
    height: 314px;
    color: white;
    overflow: hidden;
}

.requirements-header {
    padding-left: 10px;
    margin: 0;
}

.req {
    padding-left: 20px;
    font-size: .85em;
}

.req-bullet {
    padding-left: 30px;
    font-size: .75em
}

a {
  text-decoration: none;
  color: #232323;
  
  -webkit-transition: color 0.3s ease;
  
  transition: color 0.3s ease;
}

a:hover {
  color: tomato;
}

#menuToggle {
  display: block;
  position: relative;
  top: 50px;
  left: 400px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; 
  z-index: 2; 
  
  -webkit-touch-callout: none;
}

#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #cdcdcd;
  border-radius: 3px;
  
  z-index: 1;
  
  -webkit-transform-origin: 4px 0px;
  
          transform-origin: 4px 0px;
  
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuToggle span:first-child {
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span {
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2) {
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}

#menu {
  position: absolute;
  width: 300px;
  margin: -100px 0 0 -300px;
  padding: 50px;
  padding-top: 100px;
  height: 164px;
  margin-top: -77px;
  
  background: #ededed;
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  
  -webkit-transform-origin: 0% 0%;
  
          transform-origin: 0% 0%;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
  
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

#menuToggle input:checked ~ ul {
  -webkit-transform: none;
          transform: none;
}

.req-lower {
    display: grid;
    grid-template-columns: 2fr 1.25fr;
}

.apply-head-lower {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.apply-online-btn {
    margin-left: 15px;
    cursor: pointer;
}

.w4-btn {
    margin-right: 15px;
}

.apply-online-btn, .w4-btn {
    border-top: 1px solid #96d1f8;
    background: #65a9d7;
    background: -webkit-gradient(linear, left top, left bottom, from(#3e779d), to(#65a9d7));
    background: -webkit-linear-gradient(top, #3e779d, #65a9d7);
    background: -o-linear-gradient(top, #3e779d, #65a9d7);
    padding: 5px 10px;
    border-radius: 8px;
    box-shadow: rgba(0,0,0,1) 0 1px 0;
    text-shadow: rgba(0,0,0,.4) 0 1px 0;
    color: white;
    font-size: 35px;
    font-family: Georgia, serif;
    text-decoration: none;
    vertical-align: middle;
}

.apply-online-btn:hover, .w4-btn:hover {
    border-top-color: #28597a;
    background: #28597a;
    color: #ccc;
}

.apply-online-btn:active, .w4-btn:active {
    border-top-color: #1b435e;
    background: #1b435e;
}

.req-header2 {
    padding-left: 25px;
    opacity: .9;
}

.req2 {
    padding-left: 35px;
    opacity: .9;
}

@media screen and (max-width: 1100px) {
    .stock-life {
      max-width: 100%;
      height: auto;
      width: auto\9; /* ie8 */
    }

    #menuToggle {
      display: block;
      position: relative;
      top: 60px;
      left: 350px;
      
      z-index: 1;
      
      -webkit-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
    }
}

@media screen and (max-width: 1000px) {
    .requirements-header {
      font-size: .75em;
    }

    .req {
      font-size: .65em;
    }

    .req-bullet {
      font-size: .5em;
    }

    #menuToggle {
      left: 300px;
    }
}

@media screen and (max-width: 875px) {
  .apply-top {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  #menuToggle {
    left: 780px;
  }

  .requirements-header {
    font-size: 1.5em;
  }

  .req {
    font-size: .75em;
  }

  .req-bullet {
    font-size: .65em;
  }
}

@media screen and (max-width: 780px) {
  #menuToggle {
    left: 680px;
  }
}

@media screen and (max-width: 700px) {
  #menuToggle {
    left: 600px;
  }
}

@media screen and (max-width: 620px) {
  #menuToggle {
    left: 530px;
  }
}

@media screen and (max-width: 550px) {
  #menuToggle {
    left: 450px;
  }

  .apply-online-btn, .w4-btn {
    font-size: .75em;
  }
}

@media screen and (max-width: 480px) {
  #menuToggle {
    top: 80px;
    left: 380px;
  }
}

@media screen and (max-width: 480px) {
  #menuToggle {
    left: 320px;
  }
}

@media screen and (max-width: 375px) {
  .header-holder {
    padding: 5px;
  }

  #menuToggle {
    left: 275px;
  }
}
.application {
    background-color: #A3C8C0;
}

.application-header, .complete-fields {
    color: white;
    padding-left: 15px;
}

.star {
    color: red;
}

.name-input {
    width: 30%;
}

.personal-info {
    padding-left: 50px;
}

hr {
    width: 85%;
}

.position-field {
    padding-left: 50px;
}

.comments-input {
    height: 400px;
    width: 60%;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-top: 25px;
}

.application-btn {
    border-top: 1px solid #96d1f8;
    background: #65a9d7;
    background: -webkit-gradient(linear, left top, left bottom, from(#3e779d), to(#65a9d7));
    background: -webkit-linear-gradient(top, #3e779d, #65a9d7);
    background: -o-linear-gradient(top, #3e779d, #65a9d7);
    padding: 5px 10px;
    border-radius: 8px;
    box-shadow: rgba(0,0,0,1) 0 1px 0;
    text-shadow: rgba(0,0,0,.4) 0 1px 0;
    color: white;
    font-size: 35px;
    font-family: Georgia, serif;
    text-decoration: none;
    vertical-align: middle;
    margin-left: 50px;
    margin-bottom: 50px;
}

.application-btn:hover {
    border-top-color: #28597a;
    background: #28597a;
    color: #ccc;
}

.application-btn:active {
    border-top-color: #1b435e;
    background: #1b435e;
}

.application-btn:disabled {
    opacity: .4;
    cursor: not-allowed;
}
.contact {
    display: grid;
    grid-template-rows: 1fr 1fr;
    height: 83vh;
}

.upper-contact {
    background-color: #F6F6F6;
    height: 100%;
}

.lower-contact {
    background-color: #3A486D;
}

.contact-header {
    padding-left: 30px;
    color: #3A486D;
}

.contact-info {
    padding-left: 50px;
    color: #3A486D;  
}
.paypal {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #39486D;
    height: 83vh;
}

.payment-header {
    color: white;
    font-size: 3em;
    margin-top: 0px;
}

.payment-instructions {
    color: white;
}

.paypal-info {
    position: relative;
    background-color: rgba(0, 0, 0, .65);
    height: 400px;
    width: 325px;
    border-radius: 10px;
    padding: 35px;
    padding-top: 50px;
    color: white;
}

.total-payment-input {
    margin-bottom: 25px;
}

.payment-option {
    color: red;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Overpass', sans-serif;
}

