.application {
    background-color: #A3C8C0;
}

.application-header, .complete-fields {
    color: white;
    padding-left: 15px;
}

.star {
    color: red;
}

.name-input {
    width: 30%;
}

.personal-info {
    padding-left: 50px;
}

hr {
    width: 85%;
}

.position-field {
    padding-left: 50px;
}

.comments-input {
    height: 400px;
    width: 60%;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-top: 25px;
}

.application-btn {
    border-top: 1px solid #96d1f8;
    background: #65a9d7;
    background: -webkit-gradient(linear, left top, left bottom, from(#3e779d), to(#65a9d7));
    background: -webkit-linear-gradient(top, #3e779d, #65a9d7);
    background: -moz-linear-gradient(top, #3e779d, #65a9d7);
    background: -ms-linear-gradient(top, #3e779d, #65a9d7);
    background: -o-linear-gradient(top, #3e779d, #65a9d7);
    padding: 5px 10px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    -webkit-box-shadow: rgba(0,0,0,1) 0 1px 0;
    -moz-box-shadow: rgba(0,0,0,1) 0 1px 0;
    box-shadow: rgba(0,0,0,1) 0 1px 0;
    text-shadow: rgba(0,0,0,.4) 0 1px 0;
    color: white;
    font-size: 35px;
    font-family: Georgia, serif;
    text-decoration: none;
    vertical-align: middle;
    margin-left: 50px;
    margin-bottom: 50px;
}

.application-btn:hover {
    border-top-color: #28597a;
    background: #28597a;
    color: #ccc;
}

.application-btn:active {
    border-top-color: #1b435e;
    background: #1b435e;
}

.application-btn:disabled {
    opacity: .4;
    cursor: not-allowed;
}